import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Alert,
  Container,
  InputAdornment,
  IconButton,
  Paper,
} from '@mui/material';
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/glide-logo-vertical.svg';

const SignInPage = () => {
  const [email, setEmail] = useState(''); // Email state
  const [password, setPassword] = useState(''); // Password state
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [error, setError] = useState(''); // Error state
  const [loading, setLoading] = useState(false); // Loading state to manage async operation
  const [success, setSuccess] = useState(false); // Success state for animation
  const { isAuthenticated, login } = useAuth(); // Get authentication status and login function from auth context
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home'); // Redirect to home if already authenticated
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ email, password }); // Use the login method from auth context
      setSuccess(true); // Set success state to true to trigger the animation
      setError(''); // Clear any previous errors
      setLoading(false); // Stop loading state

      setTimeout(() => {
        navigate('/home'); // Redirect to home page after a delay
      }, 2000); // 2-second delay to show success animation
    } catch (err) {
      setError(err.message || 'Sign-in failed. Please try again later.');
      setLoading(false);
    }
  };

  // Toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Container maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, textAlign: 'center', width: '100%' }}>
        <Box component="img" src={logo} alt="Logo" sx={{ width: 150, mb: 4 }} /> {/* Larger logo size */}
        {!success ? (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Login
            </Typography>
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && <Alert severity="error">{error}</Alert>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              sx={{
                height: 56,
                backgroundColor: '#020420',
                color: '#ffffff',
                '&:hover': { backgroundColor: '#020420' },
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              color: 'success.main',
            }}
          >
            <CheckCircle sx={{ fontSize: 64 }} />
            <Typography variant="h5" component="h1" sx={{ mt: 2 }}>
              Sign-In Successful!
            </Typography>
            <Typography variant="body1">Redirecting you to the home page...</Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default SignInPage;
